import React from 'react'
import { Helmet } from 'react-helmet'
import { StaticImage } from "gatsby-plugin-image"
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Student Projects - Kimberly Plomp, PhD</title>
            <meta name="description" content="Student projects" />
            <meta name="keywords" content="students,teaching,research,projects" />
        </Helmet>

        <BannerLanding title="Student Projects" subtitle="What my awesome students are up to" />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Students</h2>
                    </header>
                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <StaticImage src="../assets/images/minh_tran_thi.webp" alt="Tran Thi Minh" className="image" objectFit="contain" />
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Tran Thi Minh</h3>
                            </header>
                            <p>Minh holds a both bachelor’s and master’s degrees in Biological Anthropology from Vietnam National University- Hanoi University of Science (VNU-HUS). Currently, she is a PhD student in at the School of Archaeology, University of the Philippines Diliman.</p>
							<p>Her thesis, titled <i>“Human skeletal variation of Vietnamese populations through time from foraging to farming: An evolutionary perspective on health and disease”</i>, has two key aims. The first is to combine archaeology, paleopathology, evolutionary theory, and morphometrics to investigate how activity and behaviour changes associated with key subsistence and demographic transition periods in Vietnam have influenced human skeletal morphology and health. The second aim is to investigate if there is a relationship between morphology and health by identifying and diagnosing skeletal markers of disease and trauma on the same individuals and if a relationship is found, how it changes throughout the behavioural transitions.</p>
							<p>Along with this, she is a main researcher in the Department of Palaeoanthropology and Palaeoenvironment in the Institute of Archaeology, Vietnam, cooperating study with some universities such as ANU (Australia), Copenhagen University (Denmark), Sapporo Medical University (Japan). She is also responsible for translating international archaeological news in the Vietnamese Archaeology website. Minh’s PhD research is funded by a Wadsworth international fellowship through the Wenner Gren Foundation.</p>
                        </div>
                    </div>
                </section>
				<section>
                    <StaticImage src="../assets/images/kate_purnell.webp" alt="Kate Purnell" className="image" objectFit="contain" />
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Kate Purnell</h3>
                            </header>
                            <p>Kate is a MSc student of the Human Osteoarchaeology, Palaeopathology, and Evolution Laboratory of the University of the Philippines School of Archaeology. At the same university, she also completed her BA in Anthropology and her Graduate Diploma in Archaeology.</p>
							<p>Her ongoing master’s thesis centers on identifying skeletal indicators of stress and metabolic health by looking at a 2000-year old burial complex at the Napa Site in Catanauan, Quezon. She is investigating the relationship of demographic variables with pathology prevalence. This work will be conducted through the recording and analysis of dental, cranial, and long bone remains, and compared with other Philippine sites of similar time period in an attempt to contextualize the quality of life of this focus population.</p>
							<p>Aside from progressing with her master’s degree, Kate is also an advocate of public archaeology and heritage through her work with the Tuklas Pilipinas Society and the Kapisanan ng mga Arkeologist sa Pilipinas, Inc. She is also currently a lecturer for the Science and Society Program of the College of Science in the University of the Philippines.</p>
                        </div>
                    </div>
                </section>
                <section>
                    <StaticImage src="../assets/images/dean_arcega.webp" alt="Dean Arcega" className="image" objectFit="contain" />
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Dean Arcega</h3>
                            </header>
                            <p>Dean is an MSc candidate at the University of the Philippines Diliman and Visiting Fellow at Simon Fraser University. For this thesis, he is testing the Evolutionary Shape Hypothesis (Plomp et al. 2022; Collard et al. 2022) by investigating how spinal osteoarthritis is associated with morphological variations of the spine related to bipedalism. His work involves photogrammetry and 3D geometric morphometrics. His research is funded by Global Affairs Canada.</p>
                        </div>
                    </div>
                </section>
                <section>
                    <StaticImage src="../assets/images/nicole_casing.webp" alt="Nicole Casing" className="image" objectFit="contain" />
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Nicole Casing</h3>
                            </header>
                            <p>Nicole is an MS in Archaeology student at the University of the Philippines Diliman, School of Archaeology. She finished her Bachelor of Forensic Science at the University of Baguio.</p>
							<p>Her master’s thesis will focus on assessing the accuracy of existing regression equations for stature estimation in the Filipino population. She will use CT scans of Filipino patients from the Philippine General Hospital and complete skeletons from the osteological collection at the University of the Philippines, Diliman. From the samples, she will compute stature estimates using existing regression analysis for Asian/Mongoloid and other racial groups and compare the stature estimates to the antemortem skeletal data of the samples to determine the level of accuracy of each existing regression equation. She also aims to develop a regression equation for stature estimation specific to the Filipino population.</p>
                        </div>
                    </div>
                </section>
                <section>
                    <StaticImage src="../assets/images/marvin_dorosan.webp" alt="Marvin D. Dorosan" className="image" objectFit="contain" />
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Marvin D. Dorosan</h3>
                            </header>
                            <p>Marvin is a MA Archaeology student at the University of the Philippines Diliman. He completed his BA in Anthropology degree from the same university in 2023. His research interests mainly revolve around the Anthropology of Space and Place, Southeast Asian Archaeology, Forensic Anthropology, and Heritage and Museum Studies. Marvin is actively involved in the academic community as an active member of the Kapisanan ng mga Arkeologist ng Pilipinas, Inc. (KAPI) and the Ugnayang Pang-AghamTao, Inc. (UGAT). Currently, he is working as a research assistant exploring the impacts of pre-colonial and colonial land use in Batangas, Philippines through human osteoarchaeological investigations. This is part of the PANTROPOCENE Project, a project initiated by the Max Planck Institute of Geoanthropology.</p>
                        </div>
                    </div>
                </section>
                <section>
                    <StaticImage src="../assets/images/ara_mariano.webp" alt="Ara Mariano" className="image" objectFit="contain" />
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Ara Mariano</h3>
                            </header>
                            <p>Ara is an MS Archaeology Student at the University of the Philippines Diliman. She finished her BA Anthropology degree from the same university in 2018. Her master’s thesis will focus on testing traditional sexing and ageing methods on Philippine populations.</p>
                            <p>Aside from advancing her master’s degree, Ara is also a junior faculty member of the University of the Philippines Los Baños where she teaches Anthropology and Social Forestry. She is also a Project Staff of the Gender Impact Assessment of Forest Conservation Projects among Indigenous Peoples. This project is funded by the Department of Science and Technology Philippine Council for Agriculture, Aquatic and Natural Resources Research and Development (DOST-PCAARRD).</p>
                            <p>Her research interests include Applied Anthropology, Forensic Archaeology and Anthropology, and Material Cultural Heritage.</p>
                        </div>
                    </div>
                </section>
                <section>
                    <StaticImage src="../assets/images/ian_cartalaba.webp" alt="Ian Cartalaba" className="image" objectFit="contain" />
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Adrian Peter 'Ian' Cartalaba</h3>
                            </header>
                            <p>Adrian Peter 'Ian' Cartalaba is an MSc student of the Human Osteoarchaeology, Paleopathology and Evolution Laboratory at the University for the Philippines School of Archaeology. He completed his undergraduate degree in BS Nursing and passed the licensure exam as a registered nurse. His research interest revolves around the morphological variation of the human body throughout evolutionary history using medical archaeology and palaeoneurology. His master’s thesis is an investigation to find physical Neanderthal traits in modern humans by studying patients with Chiari 1 Malformations. He will be comparing modern human and hominin skulls using comparative craniomorphology and geometric morphometrics. He is currently part of two National Geographic Society-funded projects; one is a regional heritage project based in Olongapo city, Philippines, and the other is an investigation of Laguna de Bay archaeology in search for the ancient settlement of Ma-i. He is also part of the investigative team of the Center of Recovery and Identification of the Missing (CRIM) of the University of Illinois Chicago in partnership with the National Museum of the Philippines. Outside of academia, he is a professional photographer and visual artist who explores the natural and cultural history of the Philippines in his works. He recently exhibited his paintings in collaboration with the Philippine Eagle Foundation at the Wildlife Center of the Philippines.</p>
                        </div>
                    </div>
                </section>
			</section>
            <section id="three">
                <div className="inner">
                    <header className="major">
                        <h2>Past students</h2>
                    </header>
                </div>
            </section>
            <section id="four" className="spotlights">
                <section>
                    <StaticImage src="../assets/images/lucy_timbrell.webp" alt="Lucy Timbrell" className="image" objectFit="contain" />
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Lucy Timbrell</h3>
                            </header>
                            <p>Lucy was a PhD student in the <a href="https://www.liverpool.ac.uk/archaeology-classics-and-egyptology/research/phd-research/lucytimbrell/" target="_blank" rel="noreferrer">Archaeology of Human Origins research group at the University of Liverpool</a>. She completed her BSc in Evolutionary Anthropology at the University of Liverpool and her MPhil in Biological Anthropological Science at the University of Cambridge.</p>
							<p>Her doctoral research involves producing a spatiotemporally explicit model of habitability during the Middle Stone Age. From this, she will examine the structure of early modern human population networks using archaeological data. Her work involves climatic modelling, GIS and geometric morphometric techniques. As a result of the pandemic, Lucy is developing a remote collaborative model for data collection for geometric morphometric analysis from photographs from multiple researchers all over the world. Her PhD is funded by the <a href="http://www.nwcdtp.ac.uk/current-students-2/student-profiles-2/lucy-timbrell/" target="_blank" rel="noreferrer">Arts and Humanities Research Council – North West Consortium Doctoral Training Partnership</a>, <a href="https://leakeyfoundation.org/grantee-spotlight-lucy-timbrell/" target="_blank" rel="noreferrer">The Leakey Foundation</a>, The Lithic Studies Society and The Wenner Gren Foundation.</p>
							<p>Alongside her PhD, Lucy coordinates popular science communication initiative <a href="https://conversationsinhumanevolution.com/" target="_blank" rel="noreferrer">Conversations in Human Evolution</a>, co-organises the Evolutionary Anthropology Webinar Series and is one of the founders of the NOrthern Palaeo EvoAnth (NOPE) network.</p>
                        </div>
                    </div>
                </section>				
			</section>
		</div>

    </Layout>
)

export default Landing
